import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div
      className="prose prose-a:break-all"
      dangerouslySetInnerHTML={{ __html: t('privacy-policy') }}
    />
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
